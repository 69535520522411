import React from 'react';
import {
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../components';
import pageHeroData from '../../../data/pages/patterns.yml';
import emptyStatePreviewData from '../../../data/previews/empty-states.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      containerSelect: 'main',
      stateSelectMarkState: 'default',
    };
  }

  handleContainerChange = () => {
    this.setState({
      containerSelect: this.state.containerSelect === 'main' ? 'aside' : 'main',
    });
  };

  render() {
    return (
      <PageWithSubNav pageType="design" title="Empty States" subnav="patterns">
        <PageHero heroData={pageHeroData} tierTwo="Empty States" hideHeader />
        <PageNavigation
          links={['Container', 'Position', 'Usage', 'Exceptions', 'Microcopy']}
        />

        <Section title="Container">
          <Paragraph>
            An empty state can exist in a main interface or a smaller aside.
          </Paragraph>
          <ComponentPreview
            name="PatternsContainer"
            layout="fullWidth"
            hideEnvironmentToggle
            previewData={emptyStatePreviewData.containers}
            onChange={this.handleContainerChange}>
            {this.state.containerSelect === 'main' && (
              <img
                src={require('../../../imgs/previews/preview-empty-state-main-content.jpg')}
              />
            )}
            {this.state.containerSelect === 'aside' && (
              <img
                src={require('../../../imgs/previews/preview-empty-state-aside.jpg')}
              />
            )}
          </ComponentPreview>
          <SectionSubhead>Position</SectionSubhead>
          <Paragraph>
            <strong>Main Interface:</strong> Center the text vertically and
            horizontally <em>within the viewing area</em> to clearly indicate
            the missing content affects the entire area.
          </Paragraph>
          <Paragraph>
            <strong>Aside:</strong> Vertically align within that specific
            section. Text stays left-aligned.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Value Props</SectionSubhead>
          <Paragraph>
            Certain empty states would benefit <em>big time</em> from the
            addition of a value prop. This means we go beyond telling them how
            to fill the space to <em>why they’d want to</em> and what other
            tools might help.
          </Paragraph>
          <Paragraph>
            But again, that’s only certain empty states. We don’t need to add a
            value prop every time, just stick to the features that intertwine
            with other aspects of Hudl like video (duh!), tags and reports.
          </Paragraph>
          <DontDo
            dontText="use a value prop to point out the obvious."
            doText="use value props to tie different features together."
            imgFilename="empty-states-value-props-obvious"
          />

          <Paragraph>
            Adding a value prop can be as simple as updating the text to explain
            the feature’s impact on their entire Hudl experience.
          </Paragraph>
          <DontDo
            dontText="write up an entire paragraph on how everything ties together."
            doText="precede the how with a single line about why."
            imgFilename="empty-states-value-props-paragraph"
          />

          <Paragraph>
            <strong>
              Anytime a value prop is included in the body, you must link to
              more information.
            </strong>{' '}
            Ideally, we’d send them to a tutorial or other article about the
            feature.
          </Paragraph>
          <DontDo
            dontText="leave them to learn everything on their own."
            doText="take extra steps to help them get the most out of a new tool."
            imgFilename="empty-states-value-props-linked-text"
          />
          <Paragraph>
            <em>
              Use linked text for these resources. Save buttons for actual
              actions.
            </em>
          </Paragraph>
          <Paragraph>
            Another way to communicate the true value is through sample content.
            Don’t go crazy filling the entire interface, but give them a glimpse
            of what a fully utilized feature could look like.
          </Paragraph>
          <DontDo
            dontText="bombard them with samples and distract from the message."
            doText="include a snippet of sample content to show them what’s possible."
            imgFilename="empty-states-value-props-sample-content"
          />

          <SectionSubhead>Illustrations</SectionSubhead>
          <Paragraph>
            We realize there’s likely <em>a lot</em> of negative space, but
            avoid an illustration purely for the sake of filling the empty
            state.
          </Paragraph>
          <DontDo
            dontText="distract from the key message."
            doText="use your words to explain what belongs."
            imgFilename="empty-states-illustrations"
          />
          <Paragraph>
            The only acceptable use of added context is skeleton content to help
            communicate how content would be displayed.
          </Paragraph>
          <DontDo
            dontText="use it just to liven up the page."
            doText="use skeleton content for dense interfaces."
            imgFilename="empty-states-skeletons"
          />

          <SectionSubhead>Levels</SectionSubhead>
          <Paragraph>
            The empty state does not get its own level. Keep it in line with all
            other regions on that page.
          </Paragraph>
          <DontDo
            dontText="place it higher or lower for added contrast."
            doText="follow our stack order to find the right level."
            imgFilename="empty-states-levels"
          />
          <Paragraph>
            For more about the stack order,{' '}
            <Link href="/guidelines/layering/elevation/design">
              check our level guidelines
            </Link>
            .
          </Paragraph>

          <SectionSubhead>Single Empty States</SectionSubhead>
          <Paragraph>
            You should only ever have one empty state per page. We want to make
            sure the user is properly onboarded and understands the clear next
            step before providing more context. Opt to progressively disclose
            these states.
          </Paragraph>
          <DontDo
            dontText="show them everything at once with no clear path forward."
            doText="focus on the most important missing content."
            imgFilename="empty-states-single-empty-states"
          />

          <SectionSubhead>Single CTAs</SectionSubhead>
          <Paragraph>
            Just as important as prioritized empty states are prioritized CTAs.
            Only give them one clear next step in generating the missing
            content. Too many options and we’d fail at the main goal to{' '}
            <em>educate</em>. However, on ocassion, there are{' '}
            <Link href="/patterns/empty-states#Exceptions">exceptions</Link>.
          </Paragraph>
          <DontDo
            dontText="give them a list of things to do with no clear prioritization."
            doText="focus on the most logical next step."
            imgFilename="empty-states-ctas"
          />

          <SectionSubhead>Buttons</SectionSubhead>
          <Paragraph>
            Ideally the CTA will always begin a workflow. If the workflow begins
            on that page, use our{' '}
            <Link href="/components/buttons/button/design">
              button component
            </Link>
            . If the workflow begins somewhere else, use our{' '}
            <Link href="/components/link/design#Type">link button</Link>.
          </Paragraph>
          <DontDo
            dontText="hide the CTA as linked text or a button elsewhere on the page."
            doText="use a button to clearly call out the action they can and should take."
            imgFilename="empty-states-buttons"
          />

          <SectionSubhead>Links</SectionSubhead>
          <Paragraph>
            Links should only appear in the body and take the user somewhere to
            learn more. It shouldn’t serve as the CTA or begin a workflow of any
            sort.
          </Paragraph>
          <DontDo
            dontText="frame it as a CTA in the middle of the body."
            doText="give them the option to check a tutorial for more information."
            imgFilename="empty-states-links"
          />
          <Paragraph>
            Make sure you <strong>open a new tab</strong> when taking the user
            to a new destination. We don’t want to disrupt the workflow in their
            quest for more info.
          </Paragraph>
        </Section>

        <Section title="Exceptions">
          <Section>
            <SectionSubhead>Multiple Empty States</SectionSubhead>
            <Paragraph>
              There are some instances where two workflows have no clear
              prioritization. In this case, when one piece of content doesn’t
              have to precede the other, two empty states can co-exist.
            </Paragraph>
            <Paragraph>
              <strong>
                Still, only one action should be treated as primary.
              </strong>{' '}
              Consider which empty state has the{' '}
              <em>greatest impact on the experience</em> and apply the primary
              action there. The other button should be secondary.
            </Paragraph>

            <img
              src={require('../../../imgs/previews/preview-empty-state-main-content-copy.jpg')}
              className="uni-padding--one--bottom"
            />
          </Section>
          <Section>
            <SectionSubhead>Multiple CTAs</SectionSubhead>
            <Paragraph>
              More often than not, there will only be one logical next step. If
              there are a number of ways to generate the same content, however,
              a second CTA is acceptable.
            </Paragraph>
            <Paragraph>
              This is usually the case when the body includes a value prop for a
              feature immediately available to the user.
            </Paragraph>
            <img
              src={require('../../../imgs/previews/preview-empty-state-multiple-cta.jpg')}
              className="uni-padding--one--bottom"
            />
          </Section>
          <Section>
            <SectionSubhead>Search as CTA</SectionSubhead>
            <Paragraph>
              If the actual workflow requires a search before anything else, the
              search can appear in place of the normal button. This is only if
              search is the actual first step.
            </Paragraph>
            <img
              src={require('../../../imgs/previews/preview-empty-state-search-as-cta.jpg')}
              className="uni-padding--one--bottom"
            />
          </Section>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Headline</SectionSubhead>
          <Paragraph>
            Use the{' '}
            <Link href="/components/type/headline/design">
              headline component
            </Link>
            . (For the aside, try a{' '}
            <Link href="/components/type/subhead/design">subhead</Link>{' '}
            variation.)
          </Paragraph>
          <Paragraph>
            Try limiting yourself to 6-7 words. Clearly state the purpose of
            that section and what belongs in the empty state.
          </Paragraph>
          <DontDo
            dontText="be clever and risk confusion."
            doText="tell the user exactly what’s missing."
            imgFilename="empty-states-headlines"
          />

          <SectionSubhead>Body</SectionSubhead>
          <Paragraph>
            Use <Link href="/components/type/text/design">Uniform text</Link>.
            Small should work for the aside.
          </Paragraph>
          <Paragraph>
            The body should always explain how to go about adding what’s
            missing. Don’t go into too much detail—a max. of 100 characters
            should suffice.
          </Paragraph>
          <DontDo
            dontText="explain the complete process of adding said content."
            doText="briefly touch on the benefit of adding content to that interface."
            imgFilename="empty-states-value-props-body"
          />

          <SectionSubhead>Value Props</SectionSubhead>
          <Paragraph>
            Value props should be added to the body, same text size as
            everything else. Limit it to a single sentence, likely{' '}
            <em>before</em> telling them how to get started.
          </Paragraph>
          <DontDo
            dontText="repeat the headline as you explain why the missing content matters."
            doText="feed off of the headline and expand on why they should fill the empty state."
            imgFilename="empty-states-body"
          />

          <SectionSubhead>Linked Text</SectionSubhead>
          <Paragraph>
            Our <Link href="/components/link/design">link guidelines</Link>{' '}
            cover most of the dos and don’ts, but empty states frequently call
            for the suggestion to <strong>contact Hudl Support</strong>.
          </Paragraph>
          <Paragraph>
            If you want to include that link, spell out “contact Hudl Support”
            and link to the actual contact form. Do not give them the email
            address with a mailto: link. (And avoid typing out the phone
            number—think of the added steps!)
          </Paragraph>
          <DontDo
            dontText="give them the email address to type in themselves."
            doText="treat Hudl Support as a proper noun."
            imgFilename="empty-states-linked-text"
          />
          <Paragraph>
            For links related to value props, make sure the text clearly states
            what clicking will do. “Check out Hudl Focus” and “Learn more about
            Assist” are great, but avoid the blatant “Click here for a
            tutorial.”
          </Paragraph>

          <SectionSubhead>CTA</SectionSubhead>
          <Paragraph>
            Our{' '}
            <Link href="/components/buttons/button/design">
              button guidelines
            </Link>{' '}
            have all the details.
          </Paragraph>
          <Paragraph>
            One big thing for empty states: If a CTA already exists on that page
            or might appear once content is generated, make sure the empty state
            CTA mirrors that copy. The sooner they become familiar with a
            workflow, the better.
          </Paragraph>
          <DontDo
            dontText="mix up the language just to keep things “fresh”."
            doText="provide consistent CTAs."
            imgFilename="empty-states-cta"
          />
        </Section>
      </PageWithSubNav>
    );
  }
}
